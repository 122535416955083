<template>
  <div>
    <SubsExtractForm />
  </div>
</template>

<script>
// @ is an alias to /src
import SubsExtractForm from '@/components/SubsExtractForm.vue';

export default {
  components: {
    SubsExtractForm
}
}
</script>
