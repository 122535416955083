<template>
  <div class="subs-wrapper">
    <h1>SUBS EXTRACT</h1>

    <div class="subs-container">
      <div class="reactive">
        <p v-if="uploadTask == null">Uploading Status</p>
        <p v-else>Uploading {{ filename }}: {{ progress }}%</p>
      </div>

      <form @submit.prevent="uploadFile" class="subs-form">
        <div class="container-file">
          <label for="chooseFile">Choose a file</label>
          <input
            id="chooseFile"
            name="chooseFile"
            type="file"
            required
            ref="fileInput"
            @change="fileName"
          />
          <p v-if="filename">{{ filename }}</p>
        </div>
        <p v-if="filename" @click="deleteFile" class="delete-btn">
          Delete File
        </p>
        <button class="subs-btn" type="submit">
          <div v-if="loading" class="loader"></div>
          <span v-if="loading">Loading...</span>
          <span v-else>Upload</span>
        </button>
      </form>
    </div>
    <br />
    <div>
      <AudioTable
        v-for="(file, i) in files"
        :key="file.url"
        :name="file.file.name"
        :audio="file.url"
        :transcription="transcriptions[i].results[0].alternatives[0].transcript"
      />
      <hr />
      <span class="btn-storage" @click="showStr = !showStr">Storage</span>
      <AudioTable
        v-show="showStr"
        v-for="file in remotes"
        :key="file.name"
        :name="file.name"
        :audio="file.url"
        @deleted="deleteAudio"
      />
    </div>
  </div>
</template>

<script>
import { app } from "../FirebaseApp";
import {
  getStorage,
  ref as storageRef,
  uploadBytesResumable,
  getDownloadURL,
  listAll,
} from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";
import AudioTable from "./AudioTable.vue";

const AudioToSpeech = httpsCallable(getFunctions(app), "audioToSpeech");
const storage = getStorage(app);

export default {
  components: {
    AudioTable,
  },
  data() {
    return {
      filename: "",
      uploadTask: null,
      progress: 0,
      files: [],
      remotes: [],
      transcriptions: [],
      loading: false,
      showStr: false,
    };
  },
  methods: {
    deleteAudio(filename) {
      console.log(filename);
      this.remotes = this.remotes.filter(({ name }) => name != filename);
    },
    deleteFile() {
      this.filename = null;
      this.$refs.fileInput.value = null;
    },
    fileName(e) {
      this.filename = e.target.value;
    },
    uploadFile() {
      const file = this.$refs.fileInput.files[0];
      this.loading = true;

      this.filename = file.name;
      const filepath = `/audios/${this.filename}`;
      const uploadRef = storageRef(storage, filepath);

      this.uploadTask = uploadBytesResumable(uploadRef, file);

      this.uploadTask.on(
        "state_changed",
        this.uploadStateChanged,

        (error) => {
          console.log(error);
          this.loading = false;
        },
        async () => {
          console.log("Upload complete");

          try {
            const url = await getDownloadURL(this.uploadTask.snapshot.ref);
            this.uploadTask = null;
            this.deleteFile();
            this.transcriptions.push(
              (await AudioToSpeech({ file: filepath })).data
            );
            this.files.push({ file: file, url: url });
          } catch (err) {
            console.log(err);
          } finally {
            this.loading = false;
          }
        }
      );
    },
    uploadStateChanged(snapshot) {
      // Observe state change events such as progress, pause, and resume
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      this.progress = progress.toFixed(0);
      switch (snapshot.state) {
        case "paused":
          console.log("Upload is paused");
          break;
        case "running":
          console.log("Upload is running");
          break;
      }
    },
  },
  async mounted() {
    const uploadRef = storageRef(storage, "/audios");
    const remoteFiles = await listAll(uploadRef);
    for (let file of remoteFiles.items) {
      const ref = storageRef(storage, file.fullPath);
      const url = await getDownloadURL(ref);
      this.remotes.push({ name: file.name, url: url });
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$contColor: white;
$tileColor: rgb(75, 20, 20);
$textColor: gray;
$textColor-btn: white;
$linkcolor: #42c188;
$btnColor: rgb(75, 20, 20);
$brdColor: rgb(75, 20, 20);
@mixin vertFlex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h1 {
  color: $tileColor;
}

p {
  color: $textColor;
}

input {
  display: none;
}

label {
  cursor: pointer;
}

hr {
  margin-top: 40px;
  border: 0.02rem solid $brdColor;
}
.subs {
  &-wrapper {
    @include vertFlex;
  }

  &-container {
    width: 400px;
    min-height: 200px;
    background-color: $contColor;
    border-radius: 20px;
    @include vertFlex;
    border: 1px solid $brdColor;
  }
  &-btn {
    background-color: $btnColor;
    border: none;
    color: $textColor-btn;
    padding: 15px 32px;
    font-size: 16px;
    border-radius: 10px;
    cursor: pointer;
    margin-top: 10px;
  }

  &-form {
    @include vertFlex;
  }
}

.reactive {
  margin-bottom: 10px;
}
.container-file:hover {
  color: $linkcolor;
}

.delete-btn:hover {
  cursor: pointer;
  color: red;
}

// -------spinner--------

.loader {
  background: rgba(87, 9, 9, 0.418);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.75s, visibility 0.75s;
  @include vertFlex;
}

.loader--hidden {
  opacity: 0;
  visibility: hidden;
}

.loader::after {
  content: "";
  width: 75px;
  height: 75px;
  border: 15px solid #dddddd;
  border-top-color: $brdColor;
  border-radius: 50%;
  animation: loading 0.75s ease infinite;
}

@keyframes loading {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}

.btn-storage {
  cursor: pointer;
  color: $tileColor;
  font-size: 1.5em;
}
</style>
