<template>
  <div class="tb-container">
    <div class="tb-body">
      <h3>Name</h3>
      <p>{{ name }}</p>

      <h3>Audio</h3>
      <audio :src="audio"></audio>
      <button class="button" @click="playToggle"></button>

      <h3>Transcription</h3>
      <p>{{ transcription_ }}</p>
      <img
        v-if="transcription_ == null"
        class="iconTransc"
        src="../assets/transc-icon.png"
        alt="transcription"
        title="There is no transcription!"
      />
      <span class="btn-delete" @click="showPopup">delete file </span>
      <DeletePopup
        v-show="isPopupVisible"
        @cancel="closePopup"
        @execute="deleteObj"
      />
    </div>
  </div>
</template>

<script>
import { app } from "../FirebaseApp";
import { doc, onSnapshot } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";

import { getStorage, ref as storageRef, deleteObject } from "firebase/storage";
const storage = getStorage(app);

import DeletePopup from "./DeletePopup.vue";

export default {
  components: {
    DeletePopup,
  },
  props: ["name", "audio", "transcription"],
  data() {
    return {
      transcription_: "",
      unsub: null,
      sound: new Audio(this.audio),
      isPlaying: false,
      question: null,
      isPopupVisible: false,
    };
  },
  methods: {
    closePopup() {
      this.isPopupVisible = false;
    },
    deleteObj() {
      const deserRef = storageRef(storage, `audios/${this.name}`);
      deleteObject(deserRef)
        .then(() => {
          console.log("File deleted successfully");
          this.$emit("deleted", this.name);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onPlay() {
      this.sound.play();
      this.isPlaying = true;
    },
    onPause() {
      this.sound.pause();
      this.isPlaying = false;
    },
    playToggle(e) {
      e.target.classList.toggle("paused");
      this.isPlaying ? this.onPause() : this.onPlay();
    },
    showPopup() {
      this.isPopupVisible = true;
    },
  },
  watch: {
    transcription: {
      immediate: true,
      handler() {
        this.transcription_ = this.transcription;
      },
    },
  },
  mounted() {
    this.unsub = onSnapshot(
      doc(getFirestore(app), "audios", this.name),
      (doc) => {
        if (doc.data()) {
          this.transcription_ =
            doc.data().transcription.results[0].alternatives[0].transcript;
        }
      }
    );
  },
  unmounted() {
    this.unsub();
  },
};
</script>

<style scoped lang="scss">
@mixin vertFlex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
$contColor: white;
$brdColor: rgb(75, 20, 20);

.tb {
  &-container {
    width: 400px;
    min-height: 200px;
    background-color: $contColor;
    border-radius: 20px;
    @include vertFlex;
    margin-top: 10px;
    margin-bottom: 20px;
    border: 1px solid $brdColor;
  }
  &-body {
    @include vertFlex;
    padding: 10%;
  }
}

audio::-webkit-media-controls-fullscreen-button,
video::-webkit-media-controls-fullscreen-button {
  -webkit-appearance: media-enter-fullscreen-button;
  display: flex;
  flex: none;
  border: none;
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin-left: -5px;
  margin-right: 9px;
  padding: 0;
  background-color: initial;
  color: inherit;
}
audio {
  &::-webkit-media-controls-enclosure {
    border-radius: 10px;
    border: 1px solid $brdColor;
  }
}

//--------button.play---------

.button {
  border: 0;
  background: transparent;
  box-sizing: border-box;
  width: 0;
  height: 30px;
  border-color: transparent transparent transparent #202020;
  cursor: pointer;
  border-style: solid;
  transition: 100ms all ease;
  // border-width: 20px 0 20px 30px;
  border-width: 15px 0 15px 20px;
  margin-left: 15px;

  &.paused {
    border-style: double;
    border-width: 0px 0 0px 30px;
    height: 40px;
  }

  &:hover {
    border-color: transparent transparent transparent #404040;
  }
}

.iconTransc {
  width: 50px;
  margin-left: 10px;
}

.btn-delete {
  margin-top: 20px;
  font-size: 0.6em;

  &:hover {
    cursor: pointer;
    color: red;
  }
}
</style>
