<template>
  <div class="pop-container">
    <div class="pop-inner">
      <h2>Are you sure you want to delete ?</h2>
      <div class="pop-answers">
        <button class="pop-btn-execute" @click="execute">Confirm delete</button>
        <button class="pop-btn-cancel" @click="cancel">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [],
  data() {
    return {};
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    execute() {
      this.$emit("execute");
    },
  },
};
</script>

<style scoped lang="scss">
$contColor: white;
$textColor: gray;
$brdColor: rgb(75, 20, 20);
$btnColor: rgb(75, 20, 20);
$textColor-btn: white;
@mixin vertFlex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pop {
  &-container {
    @include vertFlex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.2);
  }

  &-inner {
    background: #fff;
    padding: 40px;
    width: 30%;
    background-color: $contColor;
    border-radius: 20px;
    border: 1px solid $brdColor;
  }

  &-answers {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  &-btn-execute {
    background-color: $btnColor;
    border: none;
    color: $textColor-btn;
    padding: 10px 10px;
    font-size: 16px;
    border-radius: 10px;
    cursor: pointer;
  }

  &-btn-cancel {
    background-color: rgba(0, 0, 0, 0.2);
    color: $textColor-btn;
    border: none;
    font-size: 16px;
    padding: 10px 10px;
    border-radius: 10px;
    cursor: pointer;
  }
}
</style>
