// import { getStorage, connectStorageEmulator } from "firebase/storage"

import {getFunctions, connectFunctionsEmulator} from 'firebase/functions';
import {getFirestore, connectFirestoreEmulator} from 'firebase/firestore'

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCidWnHGvCAkgWbqq-GO5EeQwyYR1kEvyk",
  authDomain: "subsextract.firebaseapp.com",
  projectId: "subsextract",
  storageBucket: "subsextract.appspot.com",
  messagingSenderId: "311137500400",
  appId: "1:311137500400:web:cdaa6d37faf045e5e01635"
  // databaseURL: "https://subsextraction-default-rtdb.europe-west1.firebasedatabase.app",
};
// Initialize Firebase
export const app = initializeApp(firebaseConfig);


if(window.location.hostname == "localhost") {
  // connectStorageEmulator(getStorage(app), 'localhost', 9199) // host and port where missing, @goncalo delete this comment
  connectFunctionsEmulator(getFunctions(app), 'localhost', 5001)
  connectFirestoreEmulator(getFirestore(app), 'localhost', 8000)
}
