<template>
  <router-view/>
</template>

<style lang="scss">

// $bgBody:rgb(192, 192, 192);
$bgBody:rgb(192, 192, 192);

body{
    margin:0;
    background: $bgBody;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
}

div{
  margin: 0;
}

p{
  margin: 0;
}



</style>
